<template>
  <div class="EmployeeCard_container">
    <img class="EmployeeCard_image" :src="employeeImage" :alt="altText">
    <div class="EmployeeCard_name">
      <h1 style="font-size: 1em"><b>{{ employeeName }}</b></h1>
      <p>{{ employeeJobTitle }}</p>
    </div>
    <div class="EmployeeCard_popup">
      <p class="slide-up">
        {{ employeeSummary }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeeCard",
  props: {
    employeeImage: String,
    employeeName: {
      type: String,
      default: ""
    },
    altText: {
      type: String,
      default: ""
    },
    employeeJobTitle: {
      type: String,
      default: ""
    },
    employeeSummary: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped lang="scss">
.EmployeeCard_popup {
  display: none;
}

.EmployeeCard_container {
  width: 18rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 1rem;
  display: inline-block;
  height: 20rem;
  vertical-align: top;
  position: relative;

  &:hover {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);

    .EmployeeCard_popup {
      background-color: rgba(255, 255, 255, 0.95);
      height: 100%;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      padding: 1rem;
    }
    .slide-up {
      animation: slide-up 1.5s;
    }

    @keyframes slide-up {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}

.EmployeeCard_image {
  width: 12rem;
  height: 12rem;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  padding: 1rem;
}

.EmployeeCard_name {
  text-align: center;
  padding-bottom: 1rem;
}
</style>