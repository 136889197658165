<template>
  <div>
    <div class="Footer_container" style="padding: 0 5%">
      <div class="Footer_column">
        <img style="width: 100%" :src="logo" alt="zaplume logo">
        <div class="Footer_social-media-icons">
          <a style="width: 33%" rel="noopener" href="https://www.facebook.com/Zaplume-Technologies-104362521890291" target="_blank"><img alt="Facebook logo" class="Footer_social-icon" :src="icFacebook"></a>
          <a style="width: 33%" rel="noopener" href="https://www.instagram.com/zaplumetechnologies/" target="_blank"><img alt="Instagram logo" class="Footer_social-icon" :src="icInstagram"></a>
          <a style="width: 33%" rel="noopener" href="https://twitter.com/ZaplumeTech" target="_blank"><img alt="Twitter logo" class="Footer_social-icon" :src="icTwitter"></a>
          <a style="width: 33%" rel="noopener" href="https://www.linkedin.com/company/zaplume" target="_blank"><img alt="LinkedIn logo" class="Footer_social-icon" :src="icLinkedIn"></a>
        </div>
      </div>
      <div class="Footer_column Footer_quicklinks">
        <h2 style="color: #00c6ff; text-align: center">Quick Links</h2>
        <ul style="text-align: center; list-style: none; color: white">
          <router-link class="Footer_link" to="/">About Us</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts">Service and Products</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts/websiteServices">Website Services</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts/socialMedia">Social Media</router-link>
        </ul>
      </div>
      <div class="Footer_column Footer_services">
        <h2 style="color: #00c6ff; text-align: center">Services</h2>
        <ul style="text-align: center; list-style: none; color: white">
          <router-link class="Footer_link" to="/servicesAndProducts/mobileAppBuild">Mobile App Build</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts/listingsManagement">Listings Management</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts/reviewResponses">Review Responses</router-link>
          <router-link class="Footer_link" to="/servicesAndProducts/contentGeneration">Content Generation</router-link>
        </ul>
      </div>
      <div class="Footer_column Footer_contactus">
        <h2 style="color: #00c6ff; text-align: center">Contact Us</h2>
        <li class="Footer_link"><a class="Footer_link" href="tel:+13063616841">Phone: +1(306)361-6841</a></li>
        <li class="Footer_link"><a class="Footer_link" href="mailto:contact@zaplume.com">Email: contact@zaplume.com</a></li>
        <router-link class="Footer_link" to="/contactUs">
          <button class="Footer_contact-button" style="margin-top: 5px">Contact Form</button>
        </router-link>
      </div>
    </div>
    <div class="Footer_copyright-container">
      <p>© Copyright 2021 | Zaplume Technologies</p>
    </div>
  </div>
</template>

<script>
import icFacebook from './socialIcons/facebook-128.webp'
import icInstagram from './socialIcons/instagram-128.webp'
import icTwitter from './socialIcons/twitter-128.webp'
import icLinkedIn from './socialIcons/linkedin-128.webp'
import logo from '@/assets/logos/zaplumeLgLogoShort.svg'

export default {
  name: "Footer",
  data() {
    return {
      logo,
      icFacebook,
      icInstagram,
      icTwitter,
      icLinkedIn
    }
  }
}
</script>

<style scoped lang="scss">
.Footer_container {
  width: 100%;
  height: fit-content;
  background-color: #1a1a1a;
  padding-bottom: 20px !important;
}
.Footer_column {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  margin-top: 1.5rem;
  text-align: center
}
.Footer_social-media-icons {
  width: 100%;
  text-align: center;
}
.Footer_social-icon {
  width: 50px;
  height: 50px;
  margin: 2px;
}

.Footer_link {
  text-decoration: none;
  color: white;
  display: block;
  &:hover {
    color: #bfbfbf;
  }
}

.Footer_social-icon {
  &:hover {
    cursor: pointer !important;
    color: #bfbfbf;
    z-index: 9;
  }
}
.Footer_contact-button-container {
  width: 100%;
  text-align: center;
}
.Footer_contact-button {
  cursor: pointer;
  background-color: white;
  color: #1a1a1a;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    cursor: pointer !important;
    z-index: 9;
    background-color: #bfbfbf;
    color: white;
  }
}
.Footer_copyright-container {
  width: 100%;
  height: 25px;
  color: #323232;
  text-align: center;
  background-color: #00c6ff;
}

@media (max-width:801px) {
  .Footer_container {
    height: fit-content;
    padding-bottom: 20px !important;
  }

  .Footer_column {
    width: 100%;
  }

  .Footer_quicklinks {
    display: none;
  }

  .Footer_contactus {
    display: none;
  }

  .Footer_services {
    display: none;
  }
}
</style>