<template>
  <PageLoading/>
  <NavigationBar/>
  <router-view class="content"/>
  <Footer />
</template>

<script>
import PageLoading from "@/PageLoading";
import NavigationBar from "@/components/header/NavigationBar";
import Footer from "@/components/footer/Footer";

export default {
  name: 'App',
  components: {
    PageLoading,
    NavigationBar,
    Footer
  },
  data: () => ({
    //
  }),
}
</script>

<style lang="scss">
@media (max-width:801px) {
  html, body {
    overflow-x: hidden;
    font-family: 'Roboto',serif !important;
  }
  body {
    position: relative
  }
}
</style>
