<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Content Generation"
               image-description="Get blogs and content written for you with best SEO practices"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Content Generation" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <ParagraphWithImage :is-image-left="true" title="What we can do for you?" paragraph="Have a blog? Are you looking for social media posts but are struggling to get good ideas on content? That is where we come in. We have a strong team of professional writers who can research and write as much content as needed. We can layout a large number of social media posts for you and your company so all you will need to do is click post when ever you wish! Great competitive prices for top quality content that is relevant to your business!" :image="contentGeneration1" />
      <Paragraph title="How we come up with content?" paragraph="We can get together with someone from your business to throw around a few idea’s on what you guys would like to see. As a team we will take the info from this call and devise a few more details around each idea and then send you an email. From here we can make some revisions and then once we are good to go, we will get right to work writing content for you. Our number 1 priority is assuring we deliver nothing but the best quality content that is exactly what you are after." />
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import Paragraph from "@/components/content/Paragraph";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import contentGeneration1 from "@/assets/servicePageImages/contentGeneration/contentGeneration1.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "ContentGeneration",
  components: {
    HeroImage,
    ParagraphWithImage,
    Paragraph,
    BreadCrumb
  },
  data() {
    return {
      contentGeneration1
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}
.Marketing_body {
  margin: 2.5rem 0;
}
.HeroImage_container {
  background-image: url('../../assets/heroImages/heroContentGeneration.webp');
  background-size: cover;
}
@media (max-width:801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>