<template>
  <div class="ContactUs_container">
    <HeroImage class="HeroImage_container"
               image-header="Contact Us"
               image-description="No need to ink about it, please get in touch, we want to hear from you"
               image-alt-text="Hero image placeholder"/>
    <Paragraph style="margin: 2rem auto" paragraph="If you happen to have any questions, or would maybe like to arrange a meeting to see how or if we can help your business grow and scale over the upcoming months please feel free to reach out to us using our contact form below.
Include as much or as little detail as youd like and we will reach out to you as soon as possible and almost always during our scheduled business hours." />
    <form class="ContactUs_form" @submit="sendEmail">
      <p v-if="formError" style="color: red; font-size: 18px">Error: you must enter all fields before submitting</p>
      <label>Name</label>
      <input
          type="text"
          v-model="name"
          name="name"
          placeholder="Your Name"
      >
      <label>Email</label>
      <input
          type="email"
          v-model="email"
          name="email"
          placeholder="Your Email"
      >
      <label>Message</label>
      <textarea
          name="message"
          v-model="message"
          placeholder="Message">
          </textarea>

      <input type="submit" value="Send">
      <input type="reset" value="Clear" @click="clearForm">
    </form>
  </div>
</template>

<script>
import Paragraph from "@/components/content/Paragraph";
import emailjs, {init} from "emailjs-com";
import HeroImage from "@/components/header/HeroImage";

export default {
  name: "ContactUs",
  mounted() {
    init("user_4thp9HlXH3ydkk3e2j9cz");
  },
  components: {
    Paragraph,
    HeroImage
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      formError: false
    }
  },
  methods: {
    clearForm() {
      this.formError = false
      this.name = ''
      this.email = ''
      this.message = ''
    },
    sendEmail(e) {
      e.preventDefault()
      if (this.name === "" || this.email === "" || this.message === "") {
        this.formError = true
        return
      }
      try {
        emailjs.send('service_jhpns9t', 'template_r9j4tld', {
              name: this.name,
              email: this.email,
              message: this.message
            })

      } catch(error) {

        //eslint-disable-next-line
        debugger
        console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.message = ''
    }
  }
}
</script>

<style scoped>
* {box-sizing: border-box;}

@media (max-width:801px) {
  .ContactUs_container {
    padding-top: 4rem !important;
    width: 100%;
  }
}

.HeroImage_container {
  background-image: url('../assets/heroImages/contactUs.webp');
  background-size: cover;
}

.ContactUs_container {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  padding-top: 5rem;
  width: 100%;
  text-align: center;
}

.ContactUs_form {
  width: 80%;
  margin: 2rem auto;
}

label {
  float: left;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

input[type=reset] {
  background-color: #af4c4c;
  color: white;
  padding: 12px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=reset]:hover {
  background-color: #9b4040;
}
</style>