<template>
  <div class="ServicesAndProducts_container">
    <HeroImage class="HeroImage_container"
               image-header="Services And Products"
               image-description="We have all the services you need from A to Z"
               image-alt-text="Hero image placeholder"/>
    <div class="ProductFeatureCard_section">
      <ProductFeaturedCard background-url="websiteServices" class="ProductFeatureCard_container" title="Website Services" route="/servicesAndProducts/websiteServices" :list="['We can build a modern, slick website to show off your company.', 'We use all best practices to assure SEO is the best it can be.', 'Have a website you want polished up! Look no further.', 'Perhaps another company has left you with an unfinished website, we can.']"/>
      <ProductFeaturedCard background-url="listingsManagement" class="ProductFeatureCard_container" title="Listings Management" route="/servicesAndProducts/listingsManagement" :list="['We will continue to maintain and manage your online businesses details.', 'This assures when clients look up your business all information they need is accessible and accurate.', 'We can help you get noticed! We can claim and manage your Google My Business, Yelp, Bing, all your listings.']"/>
      <ProductFeaturedCard background-url="socialMedia" class="ProductFeatureCard_container" title="Social Media" route="/servicesAndProducts/socialMedia" :list="['We will help manage your online presence.', 'Assure your online accounts stay active and up-to-date.', 'Have new up-to-date online social accounts ready for new engagement.']"/>
      <ProductFeaturedCard background-url="contentGeneration" class="ProductFeatureCard_container" title="Content Generation" route="/servicesAndProducts/contentGeneration" :list="['Blog, Post or anything else, we can write for you!', 'A Collaborative process allows us to create content that you can use over months to come for your online social presence.', 'Posts, Blogs and Captions and whatever else you need!']"/>
      <ProductFeaturedCard background-url="reviewResponses" class="ProductFeatureCard_container" title="Review Responses" route="/servicesAndProducts/reviewResponses" :list="['Engaging with users who have left online reviews is important, and we can do it for you!']"/>
      <ProductFeaturedCard background-url="mobileAppBuild" class="ProductFeatureCard_container" title="Mobile App Build" route="/servicesAndProducts/mobileAppBuild" :list="['We can build you an iOS and Android app for whatever suits your needs!']"/>
    </div>
   </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import ProductFeaturedCard from "@/components/ProductFeaturedCard";

export default {
  name: "ServicesAndProducts",
  components: {
    ProductFeaturedCard,
    HeroImage
  }
}
</script>

<style scoped lang="scss">
.ProductFeatureCard_section {
  margin: 1% 0;
}
.ServicesAndProducts_container {
  text-align: center;
  padding-top: 5rem;
}
.HeroImage_container {
  background-image: url('../assets/heroImages/servicesAndProducts.webp');
  background-size: cover;
}

.ProductFeatureCard_revamp, .ProductFeatureCard_external, .ProductFeatureCard_internal, .ProductFeatureCard_container {
  &:hover {
    transition: all 0.1s ease;
  }
}

@media (max-width:801px) {
  .ServicesAndProducts_container {
    padding-top: 4rem !important;
  }
}
</style>