<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Website Services"
               image-description="Get a Custom Site Built, Optimized for SEO and Mobile"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Website Services" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <Paragraph
          title="How we build?"
          paragraph="For starters there are typically two ways to build websites. Number one would be from scratching. Coding it start to finish gives quite a few benefits. For instance it gives you 100% freedom over every little piece of the website, anything and everything you want changed becomes possible. Another huge Pro is you can optimize these website much easier making them load incredibly fast thus giving the user the best experience! The other option for building sites is to use a builder such as Wordpress. This gives the easy ability to edit the site in the future, we can build you a website and essentially hand it over to you so you can then go ahead and manage it yourself, or we can continue to maintain it."/>
      <ParagraphWithImage :is-image-left="true"
                          title="How much does it cost?"
                          paragraph="This is of course a difficult question to answer. Building a website from scratch takes longer and generally will cost more. Wordpress websites can be build much faster, obviously lowering the cost. The ideal way to approach websites is to contact us using our contact form so we can chat together, work out the details of what’s desired and then we can give you a few different options around a few different price point. For simple static websites we will charge 300$ for the landing page, and an additional 200$ for each page on top of that."
                          :image="websiteServices1"/>
      <div style="background-color: #1a1a1a; width: 100%; color: white; text-align: start">
        <h1 style="width: 80%; margin: 0 auto; padding: 2rem 0">Who maintains the website?</h1>
        <p style="width: 80%; font-size: 22px; margin: 0 auto 1rem auto; padding: 0 0 2rem 0">Well I suppose this portion of the project will be up to you. Our Wordpress website can easily be editable and we can offer a quick walk through that can be recorded for future reference. The walk through will help you make simple edits, such as text changes when ever you wish. For our custom built websites we will likely be making changes when requested, and these changes can vary in price. Quick changes like text/image changes or even a few additions are generally very cheap but they are considered on a part by part basis!</p>
      </div>
      <ParagraphWithImage title="In Summary"
                          paragraph="I know this page is rather daunting with a lot to consider however we would love to chat, over the phone or even zoom or email. We offer flexible, competitive prices that we promise we deliver on. Generally with websites the less you pay up from the more you pay down the road, similar to buying an older vehicle! But we get it, not everyone has a big budget out the gate and that’s why we want to work out the best price, and best product for you and your company!"
                          :image="websiteServices2"/>
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import Paragraph from "@/components/content/Paragraph";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import websiteServices1 from "@/assets/servicePageImages/websiteServices/websiteServices1.webp"
import websiteServices2 from "@/assets/servicePageImages/websiteServices/websiteServices2.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "WebsiteServices",
  components: {
    HeroImage,
    ParagraphWithImage,
    Paragraph,
    BreadCrumb
  },
  data() {
    return {
      websiteServices1,
      websiteServices2
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}

.Marketing_body {
  margin: 2.5rem 0;
}

.HeroImage_container {
  background-image: url('../../assets/heroImages/heroWebsiteServices.webp');
  background-size: cover;
}

@media (max-width: 801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>