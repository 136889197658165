<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Social Media"
               image-description="Grow your Social Media presence by publishing stories, posts and boosting them"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Social Media" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <ParagraphWithImage title="What we do?" paragraph="Posting to social media is really only 10% of what we mean when we say it. Creating content and sharing it with followers is important, however we need to develop complex strong strategies on how we can maximize our social posts. No post should go up without a plan. Our team comes up with in depth online social media campaign strategies to assure we get the most amount of reach with our posts as possible. Posting to social media is easy, but utilizing our knowledge on algorithms, keywords, and technology as a whole is crucial in assuring you are going to start to see an increase in business." :image="socialMedia1"/>
      <ParagraphWithImage title="What separates us from others?" :is-image-left="true" paragraph="Most companies will simply post stock images with a plain caption, along with special holiday posts to fill up your social media accounts. We as a team area always after the unorthodox strategies as those always yield the best results! If using simple templates worked, everyone would do it. That is why we make it our goal to separate you from your competitors at all times. We work with you to develop the strategy we will use going forward and from then on we will execute it and make adjustments as needed to make sure each post has a valid reason to go up." :image="socialMedia2"/>
      <ParagraphWithImage title="Why posting is so important?" paragraph="A survey completed 2 years ago showed that over 60% of Instagram users have bought an item/service off of instagram in the last 6 months. With numbers like this businesses must see the importance to having a social media that is up-to-date and fresh with posts. Consumers love to investigate companies, especially those who offer services. Social media is a powerful modern day tool to do your due diligence before making a big risk in paying for services from a business." :image="socialMedia3"/>
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import socialMedia1 from "@/assets/servicePageImages/socialMedia/socialMedia1.webp"
import socialMedia2 from "@/assets/servicePageImages/socialMedia/socialMedia2.webp"
import socialMedia3 from "@/assets/servicePageImages/socialMedia/socialMedia3.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "SocialMedia",
  components: {
    HeroImage,
    ParagraphWithImage,
    BreadCrumb
  },
  data() {
    return {
      socialMedia1,
      socialMedia2,
      socialMedia3
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}
.Marketing_body {
  margin: 2.5rem 0;
}
.HeroImage_container {
  background-image: url('../../assets/heroImages/heroSocialMedia.webp');
  background-size: cover;
}
@media (max-width:801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>