<template>
  <div class="AboutUs_container">
    <HeroImage class="HeroImage_container"
               image-header="Zaplume"
               image-description="Learn more about our talented Zaplume team"
               image-alt-text="Hero image placeholder"/>
    <div class="OurProcess_body">
      <v-spacer style="height: 10px" />
      <ParagraphWithImage
          :image="aboutUs1"
          title="Who are we?"
          alt-text="Laptop found on surface of desk"
          paragraph="Websites, Software, Apps, and Social Media! Hopefully you haven’t been scared away yet because despite all the complexities involved in all of these things we can take the weight off your shoulders. We are professionals at managing your online social presence and helping build and scale your company."/>
      <ParagraphWithImage
          :image="aboutUs2"
          alt-text="Image demonstrating strategy lifecycle"
          paragraph="We may not have a large team but we have all the expertise needed to cover all areas. Constant research is required to assure we are using up-to-date tactics to get the most out of modern day social media tools. We have access to a diverse team of developers who can build websites, apps, and simple internal software to help streamline your work."/>
      <div style="background-color: #1a1a1a; width: 100%; color: white; text-align: start">
        <h1 style="width: 80%; margin: 0 auto; padding: 2rem 0">How Are We Different?</h1>
        <p style="width: 80%; font-size: 22px; margin: 0 auto 1rem auto; padding: 0 0 2rem 0">Marketing is such a convoluted term these days it’s hard to find anyone who does anything different. Everyone talks of marketing the exact same, posting and keeping up-to-date online and helping build and grow your online presence. If you have looked around at other companies I am sure you have heard the same story over and over. While we practice these techniques we keep it our priority to be unorthodox. Its important to us that we are constantly trying new strategies and holding on tightly to things that worked and letting behind anything that didn’t do as well!</p>
      </div>
      <ParagraphWithImage :image="aboutUs3"
                          title="Interested?"
                          alt-text="Laptop found on surface of desk"
                          paragraph="No one likes to look around for help with scaling or growing their business seeing websites and posts filled with big promises and pages of salesman speech. Here at Zaplume we keep things simple and straight forward. Conversations are always welcome and are free. Let’s chat and find out if we would be a good fit to help level up your company to whatever that next goal may be!"/>
      <div>
        <h1>Meet Team Leaders</h1>
        <EmployeeSection/>
      </div>
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import aboutUs1 from "@/assets/aboutUs/aboutUs1.webp";
import aboutUs2 from "@/assets/aboutUs/aboutUs2.webp";
import aboutUs3 from "@/assets/aboutUs/aboutUs3.webp";
import EmployeeSection from "@/components/content/EmployeeSection";

export default {
  name: "AboutUs",
  data() {
    return {
      aboutUs1,
      aboutUs2,
      aboutUs3
    }
  },
  components: {
    HeroImage,
    ParagraphWithImage,
    EmployeeSection
  }
}
</script>

<style scoped>
@media (max-width:801px) {
  .AboutUs_container {
    padding-top: 4rem !important;
    width: 100%;
  }
}

.AboutUs_container {
  text-align: center;
  padding-top: 5rem;
}
.HeroImage_container {
  background-image: url('../assets/heroImages/howCanWeHelp.webp');
  background-size: cover;
}
</style>