<template>
  <div>
    <h1 v-if="title !== ''" class="ParagraphWithImage_title">{{ title }}</h1>
    <p class="Paragraph_container">{{ paragraph }}</p>
  </div>
</template>

<script>
export default {
  name: "Paragraph",
  props: {
    title: {
      type: String,
      default: ""
    },
    paragraph: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
.Paragraph_container {
  font-size: 22px;
  width: 80%;
  margin: 0 auto;
  text-align: start;
}
</style>