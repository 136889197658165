import { createApp } from 'vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import App from './App'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faPlus, faArrowRight, faQuoteLeft, faQuoteRight, faMinus, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhone, faPlus, faArrowRight, faQuoteLeft, faQuoteRight, faMinus, faBars);

const app = createApp(App)
app.use(router)
app.use(vuetify)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

