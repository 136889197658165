<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Listings Management"
               image-description="Get your Google My Business, Yelp, Bing and all your listing pages claimed and optimized"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Listings Management" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <ParagraphWithImage title="What are listings?" :is-image-left="true" paragraph="Typically when clients/potential clients are browsing for companies they can google a keyword in their area. Google will do its best to bring up any and all results that it can pertaining to the users search. It’s important to claim your listings on all the popular websites to assure you actually reaching the clientele you need to reach. Google my business separates a business from a random website on the internet." :image="listingsManagement1"/>
      <ParagraphWithImage title="Why are listings important?" paragraph="Listings are extremely important as it will appear to the user that you are a relevant business in the area. For instance if they google “roofing companies near me”, and you run a roofing company. It’s crucial that your business pops up right at the top of the list, contains any and all information the client may need, and it’s also extremely important that it gives off a good image of the company to assure it looks like a safe and trusted business. This should be one of the first big steps to creating your online presence!" :image="listingsManagement2"/>
      <ParagraphWithImage title="What we do?" :is-image-left="true" paragraph="Using our in depth knowledge of the popular listing services we are able to cooperate with you to make sure these listings are claimed and accurate. Working together we can assure that we give users the ultimate first impression to make sure they chose you over the competition!" :image="listingsManagement3"/>
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import listingsManagement1 from "@/assets/servicePageImages/listingsManagement/listingsManagement1.webp"
import listingsManagement2 from "@/assets/servicePageImages/listingsManagement/listingsManagement2.webp"
import listingsManagement3 from "@/assets/servicePageImages/listingsManagement/listingsManagement3.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "ListingsManagement",
  components: {
    HeroImage,
    ParagraphWithImage,
    BreadCrumb
  },
  data() {
    return {
      listingsManagement1,
      listingsManagement2,
      listingsManagement3
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}

.Marketing_body {
  margin: 2.5rem 0;
}

.HeroImage_container {
  background-image: url('../../assets/heroImages/heroListingsManagement.webp');
  background-size: cover;
}

@media (max-width: 801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>