import { createWebHistory, createRouter } from "vue-router";
import AboutUs from "@/components/AboutUs";
import ServicesAndProducts from "@/components/ServicesAndProducts";
import NotFound from '@/components/NotFound.vue'
/*import Blogs from "@/components/Blogs";
import Blog from "@/components/blog/Blog";*/
import ContactUs from "@/components/ContactUs";
import WebsiteServices from "@/components/ServicesAndProducts/WebsiteServices";
import ListingsManagement from "@/components/ServicesAndProducts/ListingsManagement";
import SocialMedia from "@/components/ServicesAndProducts/SocialMedia";
import ContentGeneration from "@/components/ServicesAndProducts/ContentGeneration";
import ReviewResponses from "@/components/ServicesAndProducts/ReviewResponses";
import MobileAppBuild from "@/components/ServicesAndProducts/MobileAppBuild";

const routes = [
    {
        path: "/",
        name: "About us",
        component: AboutUs,
    },
    {
        path: "/servicesAndProducts",
        name: "Services and products",
        component: ServicesAndProducts,
    },
    {
        path: "/servicesAndProducts/websiteServices",
        name: "WebsiteServices",
        component: WebsiteServices,
    },
    {
        path: "/servicesAndProducts/listingsManagement",
        name: "ListingsManagement",
        component: ListingsManagement,
    },
    {
        path: "/servicesAndProducts/socialMedia",
        name: "SocialMedia",
        component: SocialMedia,
    },
    {
        path: "/servicesAndProducts/contentGeneration",
        name: "ContentGeneration",
        component: ContentGeneration,
    },
    {
        path: "/servicesAndProducts/reviewResponses",
        name: "ReviewResponses",
        component: ReviewResponses,
    },
    {
        path: "/servicesAndProducts/mobileAppBuild",
        name: "MobileAppBuild",
        component: MobileAppBuild,
    },
 /*   {
        path: "/blogs",
        name: "Blogs",
        component: Blogs,

    },
    {
        path: "/blogs/:blogTitle",
        name: "Blog",
        component: Blog,
    },*/
    {
        path: "/contactUs",
        name: "Contact Us",
        component: ContactUs
    },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        component: NotFound,
    },
];

export default createRouter({
    history: createWebHistory(),
    scrollBehavior () {
        return { left: 0, top: 0 }
    },
    routes,
});