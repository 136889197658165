<template>
  <div :alt="imageAltText" class="HeroImage_container">
    <h1 class="HeroImage_header">{{ imageHeader }}</h1>
    <h2 class="HeroImage_description">{{ imageDescription }}</h2>
  </div>
</template>

<script>
export default {
  name: "HeroImage",
  props: {
    imageHeader: String,
    imageDescription: String,
    imageAltText: String
  }
}
</script>

<style scoped>
  .HeroImage_container {
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
  }

  .HeroImage_header, .HeroImage_description {
    text-align: center;
    color: white;
    font-weight: bold;
  }

  .HeroImage_header {
    padding-top: 7.5rem;
  }

  .HeroImage_description {
    padding-bottom: 7.5rem;
    font-size: 1.5em;
    font-weight: normal;
  }
</style>