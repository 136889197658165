<template>
  <div>
    <div v-if="isImageLeft" class="ParagraphWithImage_container">
      <h1 v-if="title !== ''" class="ParagraphWithImage_title">{{ title }}</h1>
      <img :src="image" class="ParagraphWithImage_image" :alt="altText">
      <p class="ParagraphWithImage_paragraph">{{ paragraph }}</p>
    </div>
    <div v-else class="ParagraphWithImage_container">
      <h1 v-if="title !== ''" class="ParagraphWithImage_title">{{ title }}</h1>
      <p class="ParagraphWithImage_paragraph">{{ paragraph }}</p>
      <img :src="image" class="ParagraphWithImage_image" :alt="altText">
    </div>
  </div>
</template>

<script>
export default {
  name: "ParagraphWithImage",
  props: {
    title: {
      type: String,
      default: ""
    },
    paragraph: {
      type: String,
      default: ""
    },
    altText: {
      type: String,
      default: ""
    },
    image: String,
    isImageLeft: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.ParagraphWithImage_container {
  width: 87%;
  margin: 0 auto;
  font-size: 18px;
}

.ParagraphWithImage_image {
  width: 35%;
  display: inline-block;
  padding: 1rem;
}

@media (max-width:801px) {
  .ParagraphWithImage_paragraph {
    width: 100% !important;
  }

  .ParagraphWithImage_image {
    width: 100% !important;
  }
}

.ParagraphWithImage_paragraph {
  width: 60%;
  display: inline-block;
  font-size: 22px;
  padding: 1rem;
  vertical-align: top;
  text-align: start;
  border-radius: 15px;
}
</style>