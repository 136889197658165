<template>
  <div class="NotFound_Container">
    <h1>404!</h1>
    <h2>Uh Oh, Looks like you ventured into uncharted territory!</h2>
    <div class="GifContainer"></div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 22px;
  }
  .GifContainer {
    width: 100%;
    height: 400px;
    background: url("../assets/animation404Round.gif") center no-repeat;
    background-size: 50%;
  }
  @media (max-width:801px) {
    .NotFound_Container {
      padding-top: 4rem !important;
      width: 100%;
    }

    .GifContainer {
      background-size: 80%;
    }
  }

  .NotFound_Container {
    padding-top: 5rem;
    text-align: center;
    color: #323232;
  }
</style>