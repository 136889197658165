<template>
  <div class="PageLoader" v-if="!isLoaded">
    <div class="GifContainer"></div>
  </div>
</template>

<script>
export default {
  name: "PageLoading",
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        this.isLoaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .GifContainer {
    width: 200px;
    height: 200px;
    background: url("assets/loading.gif") center no-repeat;
    background-size: 200px;
  }

  .PageLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #1a1a1a;
    z-index: 999;
  }
</style>