<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Mobile App Build"
               image-description="Get a mobile app built for you on Android and iPhone"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Mobile Application Build" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <ParagraphWithImage title="What we can do?" :is-image-left="true" paragraph="We have a small team of developers who can make both Android and iOS apps to fit your needs. When developing apps its important to understand there is no shortcut they way website can be made. When developing websites handy tools such as Wordpress and shopify make website development a bit easier, but when developing apps the only option is from scratch with code. This being said prices vary greatly but we offer very competitive prices and can even work with you on ways to get costs down for your business." :image="mobileAppBuild1" />
      <Paragraph title="How we do it?" paragraph="If you have decided you want an app for you business, maybe even for internal use? First step is to work with our team lead to layout generally what the app will look like, and what it will do. This process can take some time to perfect but its a big time saver down the road. Worth noting this step of the process is 100% free to you. Once we have something locked in we will begin development for either iOS or Android, we can do both but it will always be 1 at a time for simplicity reasons. As time progresses we can arrange weekly or monthly progress updates to keep you in the loop and to assure we don’t stray away from the guidelines setup in the first phase." />
      <ParagraphWithImage title="Do you need an app?" :is-image-left="true" paragraph="The question of the day is, do you really need an app? The surprising answer to hear from us as a company is, maybe not. App’s work great for e-commerce, or any sort of situation where you may want clients to keep track of a service your business offers. They offer a totally customizable unique environment that you as the business owner can control. App’s are flexible in ways websites can’t be. They create a more situational environment for the user that allows them to almost virtually be in your store. If any of this sounds good to you, please don’t hesitate to give us a call or email. Conversations are free, and we love having them!" :image="mobileAppBuild2" />
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import Paragraph from "@/components/content/Paragraph";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import mobileAppBuild1 from "@/assets/servicePageImages/mobileAppBuild/mobileAppBuild1.webp"
import mobileAppBuild2 from "@/assets/servicePageImages/mobileAppBuild/mobileAppBuild2.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "MobileAppBuild",
  components: {
    HeroImage,
    ParagraphWithImage,
    Paragraph,
    BreadCrumb
  },
  data() {
    return {
      mobileAppBuild1,
      mobileAppBuild2
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}
.Marketing_body {
  margin: 2.5rem 0;
}
.HeroImage_container {
  background-image: url('../../assets/heroImages/heroMobileAppBuild.webp');
  background-size: cover;
}
@media (max-width:801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>