<template>
  <div class="Marketing_container">
    <HeroImage class="HeroImage_container"
               image-header="Review Responses"
               image-description="Get your positive and negative reviews responded to for you"
               image-alt-text="Hero image placeholder"/>
    <BreadCrumb current-page="Review Response" style="margin-top: 2.5rem"/>
    <div class="Marketing_body">
      <ParagraphWithImage title="What we do?" :is-image-left="true" paragraph="We have strategic methods to responding to any type of review, both good and bad. Right off the bat it’s important to avoid apologizing when possible, saying sorry is an admission of guilt. A far better strategy than saying sorry over and over in hopes they will say its alright online is to simple address the issue in a reply, leave your business in a positive light, and invite the user to take the conversation offline as soon as possible. This gives any future users the impression the issue was resolved and the customer is now satisfied. Providing your potential clients with a sense of security knowing they will be taken care of from start to finish is a fantastic way to grow your business." :image="reviewResponses1" />
      <Paragraph title="Importance of responding to reviews" paragraph="Reviews are a touchy subject for most. Positive feedback is great to hear from online clients because it leaves a great testimonial to you and your products/services. However, negative reviews can be the start of the end for younger companies and for this reason addressing them should be approached with much caution and should always leave the business in a positive light, especially when the review is online and that their is a high chance of another potential customer coming across it." />
    </div>
  </div>
</template>

<script>
import HeroImage from "@/components/header/HeroImage";
import Paragraph from "@/components/content/Paragraph";
import ParagraphWithImage from "@/components/content/ParagraphWithImage";
import reviewResponses1 from "@/assets/servicePageImages/reviewResponses/reviewResponses1.webp"
import BreadCrumb from "@/components/content/BreadCrumb";

export default {
  name: "ReviewResponses",
  components: {
    HeroImage,
    ParagraphWithImage,
    Paragraph,
    BreadCrumb
  },
  data() {
    return {
      reviewResponses1
    }
  }
}
</script>

<style scoped>
.Marketing_container {
  text-align: center;
  padding-top: 5rem;
}
.Marketing_body {
  margin: 2.5rem 0;
}
.HeroImage_container {
  background-image: url('../../assets/heroImages/heroReviewResponses.webp');
  background-size: cover;
}
@media (max-width:801px) {
  .Marketing_container {
    padding-top: 4rem !important;
  }
}
</style>