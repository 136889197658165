<template>
  <div class="BreadCrumb_container">
    <p class="BreadCrumb_text">
      <span class="underline"><router-link to="/servicesAndProducts">Services and Products</router-link></span>
      <i class="arrow right"></i>{{ currentPage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    currentPage: String
  }
}
</script>

<style lang="scss" scoped>
  .BreadCrumb_text {
    //font-weight: bold;
  }
  .BreadCrumb_container {
    width: 80%;
    text-align: start;
    margin: 0 auto;
    font-size: 16px;
  }
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin: 0 5px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .underline {
    text-decoration: underline;
  }
</style>