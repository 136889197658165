<template>
  <div class="EmployeeSection_container">
    <EmployeeCard :employee-image="Jason" employee-name="Jason Leibel"
                  alt-text="Jason leibel headshot"
                  employee-job-title="Technology Expert and Lead Development Services"
                  employee-summary="Jason has had over 5 years professional experience developing cutting edge software, websites and mobile applications. Jason believes there is always a faster, smarter, and more efficient approach to every piece of work and he makes sure to find it!"/>
    <EmployeeCard :employee-image="Ivy" employee-name="Ivy Davey"
                  alt-text="Ivy Davey headshot"
                  employee-job-title="Marketing Expert and Brand Coordinator"
                  employee-summary="Ivy has lived and breathed the marketing world over the past 7 years. She spends her time researching and developing complex strategies for staying on top of new algorithms to improve SEO, clientele reach and lead generation. These are just a few of her amazing qualities and skillsets."/>
  </div>
</template>

<script>
import EmployeeCard from "@/components/content/EmployeeCard";
import Ivy from '@/assets/employees/employeeIvy.webp'
import Jason from '@/assets/employees/employeeJason.webp'

export default {
  name: "EmployeeSection",
  data() {
    return {
      Ivy,
      Jason
    }
  },
  components: {
    EmployeeCard
  }
}
</script>

<style scoped lang="scss">
.EmployeeSection_container {
  margin: 0 auto;
  text-align: center;
}
</style>