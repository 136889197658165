<template>
  <div>
    <div class="NavigationBar_container">
      <router-link to="/" style="height: 4rem; display: inline-block; float: left; margin: 0.5rem 0 0.5rem 5%;">
        <img class="" :src="Logo" alt="zaplume logo"
             style="height: 4rem; display: inline-block; float: left; margin: 0 0 0.5rem 5%;"/>
      </router-link>
      <ul class="NavigationBar_items">
        <li class="NavigationBar_item" :class="{ currentNavItem: currentRoute === '/' }">
          <router-link to="/">About us</router-link>
        </li>
        <li class="NavigationBar_item" :class="{ currentNavItem: currentRoute.includes('servicesAndProducts') }">
          <router-link to="/servicesAndProducts">Services and Products</router-link>
        </li>
        <li class="NavigationBar_item" :class="{ currentNavItem: currentRoute.includes('/contactUs') }">
          <router-link to="/contactUs">Contact Us</router-link>
        </li>
      </ul>
    </div>
    <div class="NavigationBar_container-mobile">
      <font-awesome-icon id="NavigationBar_hamburger" icon="bars" style="margin-left: 0.5rem" @click="toggleMenu"/>
      <router-link to="/" style="height: 4rem; display: inline-flex; float: right; margin-right: 0.5rem">
        <img class="" :src="Logo" style="height: 4rem;" alt="zaplume logo"/>
      </router-link>
    </div>
    <transition name="slide">
      <div v-if="navMenuOpen" class="NavigationMenu_container-mobile">
        <ul class="NavigationMenu_menu-mobile">
          <li class="NavigationMenu_menu-item-mobile" :class="{ currentNavItem: currentRoute === '/' }">
            <router-link to="/">About Us</router-link>
          </li>
          <li class="NavigationMenu_menu-item-mobile"
              :class="{ currentNavItem: currentRoute === '/servicesAndProducts' }">
            <router-link to="/servicesAndProducts">Services and Products</router-link>
          </li>
          <li class="NavigationMenu_menu-item-mobile" :class="{ currentNavItem: currentRoute === '/contactUs' }">
            <router-link to="/contactUs">Contact Us</router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import Logo from '@/assets/logos/zaplumeLgLogoShort.svg'

export default {
  name: "NavigationBar",
  data() {
    return {
      currentRoute: "/",
      Logo,
      navMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.navMenuOpen = !this.navMenuOpen
    },
    closeMenu() {
      this.navMenuOpen = false
    }
  },
  watch: {
    $route(to) {
      this.currentRoute = to.fullPath
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter-from {
  transform: translate(0, -100%);
}
.slide-enter-to {
  transform: translate(0, 0);
}
.slide-leave-to {
  transform: translate(0, -100%);
}
.NavigationBar_container {
  width: 100%;
  background-color: #1a1a1a;
  height: 5rem;
  position: fixed;
  z-index: 20;
  display: table-cell;
}

.NavigationBar_container-mobile {
  display: none;
}

.NavigationBar_items {
  background-color: #1a1a1a;
  line-height: 5rem;
  text-align: end;
  margin-right: 5%;
}

.NavigationBar_item {
  color: white;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  text-align: center;

  &.currentNavItem {
    background-color: #00c6ff !important;

    &:hover {
      background-color: #01aadb !important;
      cursor: pointer;
    }

    &:active {
      background-color: #008bb1 !important;
    }
  }

  &:hover {
    background-color: #323232;
    cursor: pointer;
  }

  &:active {
    background-color: #434343;
  }


  > a {
    color: white;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    padding: 0 15px;
    vertical-align: center;
  }
}

@media (max-width: 801px) {
  .NavigationBar_container {
    display: none;
  }
  .NavigationBar_container-mobile {
    display: inline-block;
    width: 100%;
    background-color: #1a1a1a;
    font-size: 38px;
    color: white;
    line-height: 4rem;
    position: fixed;
    z-index: 9;
  }
  #NavigationBar_hamburger {
    &:hover {
      cursor: pointer;
    }
  }
  .NavigationMenu_container-mobile {
    background-color: #1a1a1a;
    width: 100%;
    position: fixed;
    z-index: 8;
    text-align: center;
  }
  .NavigationMenu_menu-mobile {
    margin-top: 4rem;
    z-index: 8;
  }
  .NavigationMenu_menu-item-mobile {
    padding: 0.5rem;

    > a {
      text-decoration: none;
      color: white;
      font-size: 18px;
    }

    &.currentNavItem {
      background-color: #00c6ff;

      &:hover {
        background-color: #01aadb !important;
        cursor: pointer;
      }

      &:active {
        background-color: #008bb1 !important;
      }
    }

    &:hover {
      background-color: #323232;
      cursor: pointer;
    }

    &:active {
      background-color: #434343;
    }
  }
}
</style>